.btnMore {
  font-size: 50% !important;
}
.subTitle {
  font-size: 90% !important;
  text-align: center;
}
.titleCard {
  font-size: 3rem !important;
}
@media (max-width: 600px) {
  .titleCard {
    font-size: 1.5rem !important;
    text-align: center;
  }
}